.headerBox{
    background-color: #fff;
    // color: #fff;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 0;
    .brandName{
        color: #333;
        font-weight: 500;
        font-size: 1.2rem;
        text-decoration: none;
    }
    .navLink{
        a, div{
            color: #333;
            border: 1px gainsboro;
            padding-right: .5rem;
            padding-left: .5rem;
            padding: .5rem 1rem;
            text-decoration: none;
            cursor: pointer;
        }
    }
}