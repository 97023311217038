.headText{
    font-size: 1.2rem;
    margin-top: 1rem;
    font-weight: 500;
}
.productBox{
    border: 1px solid gainsboro;
    background-color: #fff;
    padding: 1rem;
    .btnBox{
        .button{
            margin-left: 1rem;
            margin-top: 0.5rem;
        }
    }
}

