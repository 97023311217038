@import '../../../styles/base.scss';

.productCard{
    margin-top: 1rem;
    box-shadow: 0 2px 18px 0 rgba(182, 178, 178, 50%);
    padding: 0.5rem;
    @include mobile(){
        box-shadow: 0 1px 3px 0 #b6b2b2;
    }
    .productImg{
        height: 12.75rem;
        width: 100%;
        object-fit: fill;
        @include mobile(){
            height: 8.5rem;
        }
    }
    .headingText{
        font-size: 1rem;
        font-weight: 600;
        padding: 0.2rem;
        color: #333;
        &:hover{
            text-decoration: none;
        }
    }
    
}