@import '../../styles/base.scss';

.loginPage{
    background-color: #477a82;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    .headingtext{
        text-align: center;
        font-weight: 600;
        font-size: 1.5rem;
        color: #333;
        padding: 1rem;
    }
    .loginBox{
        width: 25%;
        padding: 1rem;
        box-shadow: 0 2px 18px 0 rgba(182, 178, 178, 50%);
        background-color: #fff;
        border-radius: 0.2rem;
        @include mobile(){
            width: 80%;
        }
        label{
            color: #333;
            font-size: 0.9rem;
        }
    }
    .inputBox{
        width: 100%;
    }
    .submitBtn{
        margin-top: 0.5rem;
    }
}