.prodDetailsPage{
    margin-top: 1rem;
    border-radius: 0.2rem;
    box-shadow: 0 1px 1px 0 rgba(182, 178, 178, 50%);
    padding: 2rem;
    .prodImg{
        height: 15rem;
        width: 25rem;
        margin: auto;
        display: block;
        object-fit: fill;
    }
    .headText{
        font-size: 1.2rem;
        font-weight: 600;
        padding: 0.5rem;
        text-align: center;
    }
    .context{
        font-size: 0.9rem;
        text-align: justify;
    }
    .spanText{
        background-color: gold;
        margin-right: 0.5rem;
        padding: 0.2rem;
        display: inline-block;
        font-size: 0.8rem;
    }
}
