@import '../../styles/base.scss';

.containerBox{
    box-shadow: 0 2px 18px 0 rgba(182, 178, 178, 50%);
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1rem;
    @include mobile(){
        box-shadow: none;
        margin-top: 1rem;
    }
    .imgUploadBox{
        .imgUpload{
            height: 5rem;
            margin: auto;
            display: block;
        }
    }
    .preViewImg{
        height: 10rem;
        margin: auto;
        display: block;
        object-fit: contain;
    }
    
    .inputBox{
        margin-top: 1rem;
        label{
            font-size: 0.9rem;
            margin-bottom: 0.2rem;
        }
        .inputArea{
            border-radius: 0;
        }
    }
    .saveBtn{
        border-radius: 0;
        
        }
}

